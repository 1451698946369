export default defineNuxtRouteMiddleware(async ({ name, params }) => {
  const localePath = useLocalePath()
  const locale = name.substr(-2)

  const { data } = await useAsyncData(
    `page-ice-breaker-games-slug-${params.slug}-${locale.value}`,
    async () => {
      return Promise.all([
        $fetch(`/api/content/pages/ice-breaker-games-index/${locale}`),
        $fetch(`/api/content/pages/ice-breaker-games-list/${locale}`),
      ])
    },
    {
      transform: ([data, allIcebreakers]) => {
        const page = [
          ...data.allCategories.map(category => category.slug),
          ...allIcebreakers.map(model => model.slug),
        ].find(slug => slug === params.slug)

        return {
          page,
        }
      },
    },
  )

  if (!data.value.page) {
    return navigateTo(
      localePath('ice-breaker-games'),
      {
        redirectCode: 301,
      },
    )
  }
})
